import React, { useEffect, useState } from 'react';

import { message, Table, Popconfirm, Modal, Button, Input, Form, Space, Upload, Image, Breadcrumb,Switch } from 'antd';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import { API_ROUTES, APP_PARAMS, APP_ROUTES } from '../../utils/constants';
import axios from 'axios';
import { useUser } from '../../lib/customHooks';
import { getTokenFromLocalStorage } from '../../lib/common';
import { Row, Col } from 'antd/es';
import { useNavigate } from "react-router-dom";
import FormItem from 'antd/es/form/FormItem';
import { render } from '@testing-library/react';
const { TextArea } = Input;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const Category = () => {
  const { user, authenticated } = useUser()
  const [category, setCategory] = useState([])
  const [isCategoryTypeModalOpen, setIsCategoryTypeModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getCategory = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_CATEGORY,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      setCategory(response.data);
      if (selectedCategory == undefined && response.data.length > 0) {
        setSelectedCategory(response.data[0]._id);
      }
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }


  const handleDelete = async (id) => {
    try {
      const response = await axios({
        method: 'Delete',
        url: API_ROUTES.GET_CATEGORY + "/" + id,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },

      });
      message.success("Başarı bir şekilde silindi.")
      getCategory()
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const handleEdit = (record) => {
    
    setIsEdit(true);
    setSelectedCategory(record._id);
    form.setFieldsValue({
      name: record.name,
      priority: record.priority,
      isPremium: record.isPremium
    });
    setFileList(
      [{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: API_ROUTES.API_IMAGE + record.image,
      }]
    )
    setIsCategoryTypeModalOpen(true)

  }

  const onFinishCategory = async (values) => {
    
    let data = {
      "name": values.name,
      "file": fileList[0].originFileObj,
      "priority": parseInt(values.priority),
      "isPremium": values.isPremium
    }
    if (isEdit) {
      data.id = selectedCategory;
    }
    try {
      const response = await axios({
        method: 'post',
        url: API_ROUTES.GET_CATEGORY,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          //'Content-Type': 'application/json',
          "Content-Type": "multipart/form-data",
        },
        data: data
      });
      message.success( isEdit ? "Başarı bir şekilde güncellendi." : "Başarı bir şekilde eklendi.")
      getCategory()
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  };


  const renderCategory = () => {
    return (
      <>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishCategory}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Kategori İsmi"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Input placeholder="Kategori giriniz" />
          </Form.Item>
          <FormItem label="Kategori Icon" valuePropName="fileList" getValueFromEvent={normFile} name="file">

            <Upload listType="picture-card" maxCount={1}
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{
                  display: 'none',
                }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </FormItem>
          <Form.Item
            name="priority"
            label="Sıra Numarası"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Input placeholder="Sıralama" />
          </Form.Item>
          <Form.Item
            name="isPremium"
            label="Premium"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {isEdit ? "Düzenle" : "Kaydet"}
              </Button>

            </Space>
          </Form.Item>
        </Form>
       
      </>

    )
  }
  const resetForm = () => {
    form.setFieldsValue({
      name: "",
      priority: ""
    })
    setFileList([]);
    setIsCategoryTypeModalOpen(false);
    setIsEdit(false)
  }

  useEffect(() => {
    if (authenticated) {
      getCategory();

    }
  }, [authenticated])

  
  let paginationInfo = {};
  const columnsMain = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (text, record, index) => {
        // Sayfa bilgisi pagination'dan alınır
        const { current = 1, pageSize = 10 } = paginationInfo;
        return (current - 1) * pageSize + index + 1;
      },
    },
    {
      title: 'Kategori',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Kategori Görseli',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) => <Image
        width={36}
        src={API_ROUTES.API_IMAGE + "/" + record.image}
      />
    },
    {
      title: 'Sıralama',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Premium',
      dataIndex: 'isPremium',
      key: 'isPremium',
      render: (_, record) => <Switch checked={record.isPremium} />
    },
    {
      title: 'Alt Kategori Ekle',
      dataIndex: 'operation',
      render: (_, record) =>
        category.length >= 1 ? (
          <a onClick={() => navigate(APP_ROUTES.SUB_CATEGORY + "/" + record._id, { state: { categoryName: record.name } })}>Alt Kategori Ekle  </a>
        ) : null,
    },
    {
      title: 'Düzenle',
      dataIndex: 'operation',
      render: (_, record) =>
        <a onClick={() => handleEdit(record)}>Düzenle</a>
    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        category.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ]



  return (
    <>
      <div style={{ margin: "-5px 0px" }} className='flex text-lg font-bold rounded-t-3xl backdrop-blur-xl bg-white/60 justify-between p-6  flex-col lg:flex-row'>
        <div className='py-4 lg:py-0'> <Breadcrumb items={[{ title: 'Home', }, { title: 'Category' }]} /></div>
        <div className='flex gap-1'>
          <Button onClick={() => setIsCategoryTypeModalOpen(true)}>Kategori Ekle</Button>
          <Button onClick={() => navigate(APP_ROUTES.ADD_TEXT)}>Metin Ekle</Button>
          <Button onClick={() => navigate(APP_ROUTES.ADD_MEDIA)}>Görsel Ekle</Button>
        </div>

      </div>
      <Row>
        <Col span={10} offset={4}>
          <Table columns={columnsMain} dataSource={category} style={{ "marginTop": "50px" }} 
           pagination={{
            onChange: (page, pageSize) => {
              paginationInfo = { current: page, pageSize };
            },
            showSizeChanger: true, // Kullanıcının pageSize değiştirmesine izin verir
          }}/>
        </Col>
      </Row>
      {isCategoryTypeModalOpen &&
        <Modal title="Kategoriler" open={isCategoryTypeModalOpen} onOk={() => resetForm()} onCancel={() => resetForm()} destroyOnClose={true}>
          {renderCategory()}
        </Modal>
      }
    </>
  )
}

export default Category;