import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { message, Table, Popconfirm, Modal, Button, Input, Form, Space, Breadcrumb, Switch } from 'antd';

import { API_ROUTES, APP_PARAMS, APP_ROUTES } from '../../utils/constants';
import axios from 'axios';
import { useUser } from '../../lib/customHooks';
import { getTokenFromLocalStorage } from '../../lib/common';
import { Row, Col } from 'antd/es';
import FormItem from 'antd/es/form/FormItem';
const { TextArea } = Input;

const SubCategory = () => {
  const { user, authenticated } = useUser();
  const [isSubCategoryTypeModalOpen, setIsSubCategoryTypeModalOpen] = useState(false);
  const [subCategory, setSubCategory] = useState([])
  const [texts, setTexts] = useState([])
  const [medias, setMedias] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [categoryName, setCategoryName] = useState()
  const [isEdit, setIsEdit] = useState(true)

  useEffect(() => {
    if (authenticated) {
      setCategoryName(location.state.categoryName)
      //getSubCategory(id);
      getTexts()
    }
  }, [authenticated])

  const getTexts = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_TEXT,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      setTexts(response.data)
      getMedias()

    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }
  const getMedias = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_MEDIA,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      setMedias(response.data)
      getSubCategory(id)

    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }
  const getSubCategory = async (categoryId) => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_SUB_CATEGORY + "?id=" + categoryId,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      setSubCategory(response.data)

    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const getTextsCount = (subCategoryId) => {
    const count = texts.filter(item => item.subCategory.includes(subCategoryId)).length;
    return count;
  }
  const getMediasCount = (subCategoryId) => {
    const count = medias.filter(item => item.subCategory.includes(subCategoryId)).length;
    return count;
  }

  const handleEdit = (record) => {

    setIsEdit(true);
    setSelectedSubCategory(record._id);
    form.setFieldsValue({
      subCategoryName: record.name,
      priority: record.priority,
      isPremium: record.isPremium
    });

    setIsSubCategoryTypeModalOpen(true)

  }

  const handleSubCategoryDelete = async (subCategoryId) => {

    try {
      const response = await axios({
        method: 'Delete',
        url: API_ROUTES.GET_SUB_CATEGORY + "/" + subCategoryId,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },

      });
      message.success("Başarı bir şekilde silindi.")
      getSubCategory(id)
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const onFinishSubCategory = async (values) => {
    let data = {
      "category": id,
      "name": values.subCategoryName,
      "priority": parseInt(values.priority),
      "isPremium": values.isPremium
    }
    if (isEdit) {
      data.id = selectedSubCategory;
    }
    try {
      const response = await axios({
        method: 'post',
        url: API_ROUTES.GET_SUB_CATEGORY,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },
        data: data
      });
      message.success(isEdit ? "Başarı bir şekilde güncellendi." : "Başarı bir şekilde eklendi.")
      getSubCategory(id)


    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  };
  const resetForm = () => {
    form.setFieldsValue({
      subCategoryName: "",
      priority: ""
    })
    setIsSubCategoryTypeModalOpen(false)
    setIsEdit(false)
  }

  const renderSubCategory = () => {
    return (
      <>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishSubCategory}
          autoComplete="off"
        >

          <Form.Item
            name="subCategoryName"
            label="Alt Kategori İsmi"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Input placeholder="Alt kategori giriniz" />
          </Form.Item>
          <Form.Item
            name="priority"
            label="Sıra Numarası"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Input placeholder="Sıralama" />
          </Form.Item>
          <Form.Item
            name="isPremium"
            label="Premium"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>

            </Space>
          </Form.Item>
        </Form>

      </>

    )
  }

 

  let paginationInfo = {};
  const columnsMediasMain = [

    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (text, record, index) => {
        // Sayfa bilgisi pagination'dan alınır
        const { current = 1, pageSize = 10 } = paginationInfo;
        return (current - 1) * pageSize + index + 1;
      },
    },
    {
      title: 'Kategori',
      dataIndex: 'name',
      key: 'name',
    }, {
      title: 'Sıralama',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Premium',
      dataIndex: 'isPremium',
      key: 'isPremium',
      render: (_, record) => <Switch checked={record.isPremium} />
    },
    {
      title: 'Text Sayısı',
      dataIndex: 'textCount',
      key: 'textCount',
      render: (_, record) => <div>{getTextsCount(record._id)}</div>
    },
    {
      title: 'Görsel Sayısı',
      dataIndex: 'mediaCount',
      key: 'mediaCount',
      render: (_, record) => <div>{getMediasCount(record._id)}</div>
    },
    {
      title: 'Düzenle',
      dataIndex: 'operation',
      render: (_, record) =>
        <a onClick={() => handleEdit(record)}>Düzenle</a>

    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        subCategory.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleSubCategoryDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    }
  ]


  return (
    <>
      <div style={{ margin: "-5px 0px" }} className='flex text-lg font-bold rounded-t-3xl backdrop-blur-xl bg-white/60 justify-between p-6  flex-col lg:flex-row'>
        <div className='py-4 lg:py-0'><Breadcrumb items={[{ title: 'Home', }, { title: <Link to="/admin/category">Category</Link> }, { title: categoryName }]} /></div>
        <div className='flex gap-1'>
          <Button onClick={() => { setIsSubCategoryTypeModalOpen(true); }}>Alt Kategori Ekle</Button>
        </div>

      </div>
      <Row>
        <Col span={16} offset={4}>
          <Table columns={columnsMediasMain} dataSource={subCategory} style={{ "marginTop": "50px" }} 
          pagination={{
            onChange: (page, pageSize) => {
              paginationInfo = { current: page, pageSize };
            },
            showSizeChanger: true, // Kullanıcının pageSize değiştirmesine izin verir
          }}
          />
        </Col>
      </Row>

      {isSubCategoryTypeModalOpen &&
        <Modal title="Alt Kategori" open={isSubCategoryTypeModalOpen} onOk={() => resetForm()} onCancel={() => resetForm()} destroyOnClose={true}>
          {renderSubCategory()}
        </Modal>
      }

    </>
  )
}

export default SubCategory;