import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import { APP_ROUTES } from './utils/constants';
import NotFound from './pages/NotFound';
import Confirm from './pages/Confirm';
import Layout from './components/admin/layout/Layout';
import React from 'react';


import ShowPage from './pages/ShowPage';

import Users from './pages/admin/Users';

import Category from './pages/admin/Category';
import SubCategory from './pages/admin/SubCategory';
import AddText from './pages/admin/AddText';
import AddMedia from './pages/admin/AddMedia';



function App() {
  return (
    <BrowserRouter>   
      <Routes>
        <Route exact path="/" element={<Navigate to={APP_ROUTES.CATEGORY} />} />
        <Route path={APP_ROUTES.SIGN_UP} exact element={<SignUp />} />
        <Route path={APP_ROUTES.SIGN_IN} element={<SignIn />} />
       
        <Route path={APP_ROUTES.CATEGORY} element={<Layout><Category /></Layout>} />
        <Route path={APP_ROUTES.SUB_CATEGORY+"/:id"} element={<Layout><SubCategory /></Layout>} />
        <Route path={APP_ROUTES.ADD_TEXT} element={<Layout><AddText /></Layout>} />
        <Route path={APP_ROUTES.ADD_MEDIA} element={<Layout><AddMedia /></Layout>} />
        
        <Route path={APP_ROUTES.USERS} element={<Layout><Users /></Layout>} />
        <Route path={APP_ROUTES.CONFIRM} element={<Confirm />} />
        <Route path={APP_ROUTES.SHOWPAGE} element={<ShowPage />} />
      
        <Route path='*' exact={true} element={<NotFound />} />
      </Routes>       
    </BrowserRouter>
  );
}

export default App;