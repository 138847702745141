import React, { Children, useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { message, Table, Popconfirm, Modal, Button, Input, Form, Space, Select, Breadcrumb, TreeSelect } from 'antd';

import { API_ROUTES, APP_PARAMS, APP_ROUTES } from '../../utils/constants';
import axios from 'axios';
import { useUser } from '../../lib/customHooks';
import { getTokenFromLocalStorage } from '../../lib/common';
import { Row, Col } from 'antd/es';


const { TextArea } = Input;

const AddText = () => {
  const { user, authenticated } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [texts, setTexts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedText, setSelectedText] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [treeValue, setTreeValue] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();


  useEffect(() => {
    if (authenticated) {
      getCategories();

    }
  }, [authenticated])

  const onChange = (newValue) => {
    console.log('onChange ', newValue);
    setTreeValue(newValue);
  };
  const tProps = {
    treeData,
    value: treeValue,
    onChange,
    treeCheckable: true,
    placeholder: 'Please select',
    style: {
      width: '100%',
    },
  };

  const generateTreeData = () => {
    let data = [];

    categories.forEach(category => {

      let selectedSubCategories = subCategories.filter((item) => item.category === category._id);
      let tmpChild = [];
      selectedSubCategories.forEach(item => {
        let tmp = {
          title: item.name,
          value: item._id,
          key: item._id
        }
        tmpChild.push(tmp);
      });

      let tmpObj = {
        title: category.name,
        value: category._id,
        key: category._id,
        children: tmpChild
      }
      data.push(tmpObj)
    });

    setTreeData(data)

  }

  const getTexts = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_TEXT,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      setTexts(response.data);

    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }
  const getCategories = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_CATEGORY,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      setCategories(response.data);
      getSubCategories();

    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }
  const getSubCategories = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_SUB_CATEGORY,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });
      setSubCategories(response.data);
      getTexts();

    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const handleDelete = async (id) => {
    try {
      const response = await axios({
        method: 'Delete',
        url: API_ROUTES.GET_TEXT + "/" + id,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },

      });
      message.success("Başarı bir şekilde silindi.")
      getCategories();
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const handleEdit = async (record) => {
    setSelectedText(record._id);
    setIsEdit(true);
    form.setFieldsValue({
      name: record.text
    });
    generateTreeData();
    setTreeValue(record.subCategory);
    setIsModalOpen(true);
  }

  const resetForm = () => {
    form.setFieldsValue({
      name: ""
    })

    setIsModalOpen(false);
    setIsEdit(false)
  }

  const onFinishText = async (values) => {

    let data = {
      "text": values.name,
      "subCategory": treeValue
    }
    if (isEdit) {
      data.id = selectedText;
    }
    try {
      const response = await axios({
        method: 'post',
        url: API_ROUTES.GET_TEXT,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },
        data: data
      });
      message.success( isEdit ? "Başarı bir şekilde güncellendi." : "Başarı bir şekilde eklendi.")
      getCategories()
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  };

  const renderModalContent = () => {
    return (
      <>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishText}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Metin"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <TextArea placeholder="Metin giriniz" />
          </Form.Item>
          <Form.Item>
            <TreeSelect {...tProps} />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {isEdit ? "Düzenle" : "Kaydet"}
              </Button>

            </Space>
          </Form.Item>
        </Form>

      </>

    )
  }

  const getCategoriesName = (ids) => {

    const filteredSubCategories = subCategories.filter(item =>
      ids.includes(item._id)
    );

    const filteredCategory = categories.filter(item =>
      filteredSubCategories.map(subCategory => subCategory.category).includes(item._id)
    )


    return filteredCategory.map(category => category.name).join(" | ");

  }
  const getSubCategoriesName = (ids) => {
    const filteredSubCategories = subCategories.filter(item =>
      ids.includes(item._id)
    );

    return filteredSubCategories.map(category => category.name).join(" | ");

  }

  const createSubCategoryFilter = () => {
    return subCategories.map(subCategory => ({
      value: subCategory._id,       // `_id` özelliğini `id` olarak yeniden adlandır
      text: subCategory.name    // `name` özelliğini `title` olarak yeniden adlandır
    }))
  }

  const createCategoryFilter = () => {
    return categories.map(category => ({
      value: category._id,       // `_id` özelliğini `id` olarak yeniden adlandır
      text: category.name    // `name` özelliğini `title` olarak yeniden adlandır
    }))
  }
  const filterCategory = (value,record) => {
    const filteredSubCategories = subCategories.filter(item =>
      record.subCategory.includes(item._id)
    );

    const filteredCategory = categories.filter(item =>
      filteredSubCategories.map(subCategory => subCategory.category).includes(item._id)
    )
    console.log(filteredCategory,value);
    
   return filteredCategory.map(category => 
    category._id
   ).includes(value)

    return false
  }

  let paginationInfo = {};
  const columnsMain = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (text, record, index) => {
        // Sayfa bilgisi pagination'dan alınır
        const { current = 1, pageSize = 10 } = paginationInfo;
        return (current - 1) * pageSize + index + 1;
      },
    },
    {
      title: 'Metin',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Kategori',
      dataIndex: 'value',
      filters: createCategoryFilter(),
      filterMode: 'tree',
      onFilter: (value, record) => filterCategory(value,record),
      render: (_, record) =>

        <div>{getCategoriesName(record.subCategory)}</div>
    },
    {
      title: 'Alt Kategori',
      dataIndex: 'operation',
      filters: createSubCategoryFilter(),
      onFilter: (value, record) => record.subCategory.includes(value),
      filterMode: 'tree',
      render: (_, record) =>

        <div>{getSubCategoriesName(record.subCategory)}</div>
    },
    {
      title: 'Düzenle',
      dataIndex: 'operation',
      render: (_, record) =>
        <a onClick={() => handleEdit(record)}>Düzenle</a>
    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        texts.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ]

  return (
    <>
      <div style={{ margin: "-5px 0px" }} className='flex text-lg font-bold rounded-t-3xl backdrop-blur-xl bg-white/60 justify-between p-6  flex-col lg:flex-row'>
        <div className='py-4 lg:py-0' >
          <Breadcrumb items={[{ title: 'Home', }, { title: <Link to="/admin/category">Category</Link> }, { title: 'Add Text' }]}
          />
        </div>
        <div className='flex gap-1'>
          <Button onClick={() => { setIsModalOpen(true); generateTreeData(); }}>Metin Ekle</Button>
        </div>

      </div>
      <Row>
        <Col span={18} offset={2}>
          <Table columns={columnsMain} dataSource={texts} style={{ "marginTop": "50px" }} 
          pagination={{
            onChange: (page, pageSize) => {
              paginationInfo = { current: page, pageSize };
            },
            showSizeChanger: true, // Kullanıcının pageSize değiştirmesine izin verir
          }}
          />
        </Col>
      </Row>

      {isModalOpen &&
        <Modal title="Alt Kategori" open={isModalOpen} onOk={() => resetForm()} onCancel={() => resetForm()} destroyOnClose={true}>
          {renderModalContent()}
        </Modal>
      }

    </>
  )
}

export default AddText;